.main_header {
  padding-top: 16px;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.title_web {
  font-size: 36px;
  font-weight: 700;
  color: #E0E0E0;
  cursor: pointer;
}

.text_menu_tab {
  font-size: 16px;
  font-weight: 600;
  padding-right: 16px;
  align-self: center;
  color: #E0E0E0;
  cursor: pointer;
}

.right_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  flex: 1;
}

.text_menu_tap_login{
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  background-color: #0368FF;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  align-self: center;
  color: #F2F0FF;
}

.text_menu_tap_login:hover{
  background-color: #5899FF;
}