body {
  margin: 0px;
  padding: 0px;
  background: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root_view {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container_main {
  height: 100vh;
  width: 32%;
  display: flex;
  flex-direction: column;
}

.header {
  height: 50px;
  width: 100%;
}

.content {
  flex: 1; 
}

.footer {
  flex: 1; 
}

/* Điều chỉnh kích thước màn hình */
@media screen and (max-width: 1024px) {
  .container_main {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .container_main {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .container_main {
    width: 96%;
  }
}