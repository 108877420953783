.container_home {
    display: flex;
    flex: 1;
    padding: 0;
    flex-direction: column;
  }
  
  .top_home {
    height: 0px;
  }
  
  .body_home {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  .text_info_account{
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    text-align: left;
    color: #B0BEC5;
    cursor: pointer;
  }

  .text_info_tool{
    font-size: 18px;
    font-weight: 500;
    align-items: center;
    text-align: left;
    color: #B0BEC5;
    cursor: pointer;
  }

  .info_account{
    display: flex;
    flex-direction: column;
  }

  .type_account{
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    text-align: left;
    color: #E0E0E0;
    cursor: pointer;
  }
  
  .main_select_image{
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    margin-top: 24px;
    padding: 12px;
    background-color: #ffffff;
    border-radius: 8px;
  }

  .input_hide {
    display: none;
  }

  .image_select {
    width: 100px;
    height: 50px;
    background-color: rgba(29, 29, 29, .9);
    border-radius: 5px;
    cursor: pointer;
    object-fit: 'cover';
    object-position: 'center';
    padding: 10px;
  }

  .text_title_select_image{
    font-size: 16px;
    font-weight: 600;
    margin-left: 8px;
    align-items: center;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }

  .text_title_swap_image{
    font-size: 17px;
    font-weight: 700;
    align-items: center;
    margin-left: 8px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }

  .text_title_download{
    font-size: 17px;
    font-weight: 700;
    align-items: center;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }

  .main_select_face{
    width: 96%;
    display: flex;
    border: 1.5px solid #ffffff;
    align-items: center;
    background-color: #1E1E2E;
    cursor: pointer;
    margin-bottom: 8px;
    border-radius: 8px;
    flex-direction: row;
  }

  .image_result{
    width: 100%;
    margin-top: 16px;

  }

  .div3 {
    height: 100px;
    align-self: flex-end;
  }

  .main_button {
    width: 96%;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: justify-content 0.5s ease-in-out;
}

/* Khi chỉ có 1 button, nó sẽ căn giữa */
.main_button.one-button {
    justify-content: center;
}

/* Khi có 2 button, chúng sẽ cách nhau 24px */
.main_button.two-buttons {
    justify-content: space-between;
    gap: 16px;
}

.button_reface {
  opacity: 0;
  width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.button_reface, .button_download {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    height: 50px;
    margin-top: 16px;
    text-align: center;
    align-content: center;
    background-color: #5E60CE;
    border-radius: 5px;
    transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out;
}


.button_reface.full-width {
    width: 100%;
    margin-right: 0px;
}


.button_reface.half-width {
    width: 50%;
    margin-right: 10px;
}


.button_download {
    opacity: 0;
    width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.button_download.show {
    opacity: 1;
    width: 50%;
}

  /* Use a media query to add a breakpoint at 800px: */
/* @media screen and (max-width: 800px) {
  .main_select_image {
    display: flex; 
    align-items: center;
    flex-direction: column;
  }

  .image_result{
    width: 100%;
  }

  .main_select_face{
    width: 100%;
  }

  .button_reface{
    width: 100%;
  }

  .image_select {
    width: 100px;
    height: 50px;
  }
} */